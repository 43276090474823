var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "quota__details" },
    [
      _vm._l(_vm.quota.child_quotas, function (item) {
        return _c(
          "div",
          { key: item.id, staticClass: "quota__details-item" },
          [
            _c("div", { staticClass: "quota__main mod-child" }, [
              _vm._v(
                " " + _vm._s(_vm.getSupplierTitle(item.suppliers_ids[0])) + " "
              ),
            ]),
            _c("TripleProgressBar", { attrs: { quota: item } }),
          ],
          1
        )
      }),
      _c("div", { staticClass: "quota__details-item" }, [
        _c("div", { staticClass: "quota__main mod-child" }, [
          _c("div", [
            _c("span", [_vm._v("Нераспределенные таймслоты: ")]),
            _c("span", { staticClass: "title__unimproved" }, [
              _vm._v(_vm._s(_vm.getUnimprovedQuota)),
            ]),
          ]),
        ]),
        _vm._m(0),
      ]),
      !_vm.isMobile
        ? _c(
            "div",
            { staticClass: "quota__details-button" },
            [
              _c(
                "iq-button",
                {
                  attrs: { color: "main", size: "small" },
                  on: { onClick: _vm.goToTimeslots },
                },
                [_vm._v(" Показать ТС ")]
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "quota__progressbar" }, [
      _c("div", { staticClass: "progress__legend mod_unavailable" }, [
        _c("div", { staticClass: "progress__legend-icon mod_unavailable" }),
        _c("div", { staticClass: "progress__legend-text" }, [
          _vm._v("Недоступно для бронирования"),
        ]),
      ]),
      _c("div", { staticClass: "progress__legend mod_booked" }, [
        _c("div", { staticClass: "progress__legend-icon mod_booked" }),
        _c("div", { staticClass: "progress__legend-text" }, [
          _vm._v("Забронировано"),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }